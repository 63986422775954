.Content3D{
	position: relative;
	background-color:var(--bronze-light);
	width:100%;
	height:100%;	
}


.Content3D iframe{
	width: 100%;
	height: 100%;
	background-color:var(--bronze-light);
	z-index:20;
}


.Content3D .qrcode{width:100px;height:100px;display:block;position: absolute;right:20px;top:20px;z-index:100}
