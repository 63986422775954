@import './fonts/fonts.css';

body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
	user-select: none;
}

body {
	--red:#a52336;
	--bronze-light:#f3eee0;
	--bronze-middle:#d8c59d;
	--bronze-dark:#b48f65;

	--header-height: 105px;
	--footer-height: 29px;
	--border-width: 5px;

	--subfolder: '/test';
}

.noselect{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn_highlight{position: absolute;top:0;left:0;height:100%;width: 100%;pointer-events: none;background-color:transparent}
.active .btn_highlight{	animation-name: heighlight;animation-duration: .6s;}


.qrcode{background-color: white;padding:8px;}


@keyframes heighlight {
	from { background-color: white; }
		to { background-color: transparent; }
} 

.disabled{pointer-events: none;opacity: .5;}
.hidden{display:none !important;}

@-webkit-keyframes fadeIn {
	from { opacity: 0 !important; }
		to { opacity: 1 !important; }
} 
@keyframes fadeIn {
	from { opacity: 0; }
		to { opacity: 1; }
}
*:not(.exception){ animation: .5s ease-out 0s 1 fadeIn;}


html, body{width:100%;height:100%;margin:0;padding:0;overflow: hidden;}
#root{height:100%;}
.App{width:100%;height:100%;position:absolute;background-color: white;}
