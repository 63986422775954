.Footer{
	width:100%;
	height:calc(var(--footer-height) - 2 * var(--border-width));
	border-top:var(--border-width) solid white;
	border-bottom:var(--border-width) solid var(--red);
	background-color: var(--bronze-dark);
	position: absolute;
	bottom:0;
	color:white;

}

.Footer>span{padding-left:23px;font-size:15px;line-height:calc(var(--footer-height) - 2 * var(--border-width));vertical-align:top;}

.Footer>.products{--height:40px;position: relative; display:inline-block;margin-left:20px;top:-15px;height:var(--height);}
.Footer>.products>*{position: relative; display:inline-block;background-color: var(--bronze-middle);box-shadow: 0px 10px 15px 0px rgba(0,0,0,0.8);text-decoration: none;color:white;}
.Footer>.products>*.active{background-color: var(--bronze-dark);transition: all .5s;z-index:999}
.Footer>.products>*>span{margin:0 33px;height:var(--height);line-height:var(--height);}
.Footer>.products>*>.close{display:block;width:13px;height:12px;position: absolute;right:5px;top:5px;background-image: url('../../images/xMod.png');background-size: contain;background-repeat: no-repeat;}