.ContentVideo{
	position: relative;
	background-color:var(--bronze-light);
	width:100%;
	height:100%;	
	overflow:hidden
}

.ContentVideo video{
	position: absolute;
  top: 50%; 
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%; 
  min-height: 100%; 
  width: auto; 
  height: 100%;
  background-color: var(--bronze-light);
  overflow: hidden;
}

.wide .ContentVideo video
{
	width: 100%; 
  height: auto;
}

.ContentVideo .qrcode{width:100px;height:100px;display:block;position: absolute;right:20px;top:20px;z-index:100}

.ContentVideo .ctrl_btn{width:100px;height:50px;border-radius: 5px;display:block;position: absolute;right:20px;bottom:20px;z-index:99;background-color: var(--bronze-light);background-size: contain;background-position:center;background-repeat: no-repeat;opacity: 0.7;}
.ContentVideo .ctrl_btn:hover{opacity: 0.9;transition: opacity 0.5s;cursor: pointer;}
.ContentVideo .ctrl_btn{background-image: url('../../images/icon_play.png');}
.ContentVideo .ctrl_btn.pause{background-image: url('../../images/icon_pause.png');}