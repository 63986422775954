.ContentInfo{
	position: relative;
	background-color:white;
	width:calc(100% - 40px);
	height:calc(100% - 40px);
	padding: 20px;
	--scale:1;
}

.ContentInfo h1{color:var(--red);font-size:calc(30px * var(--scale));font-weight:bold;margin-bottom:15px;}
.ContentInfo h2{color:var(--bronze-dark);font-size:calc(20px * var(--scale));font-weight:normal;line-height: 1.4;white-space: pre-line}
.ContentInfo .hr{width:100%;height:1px;background-color:var(--bronze-dark);margin-top:25px;margin-bottom:30px;}


.ContentInfo .qrcode{width:100px;height:100px;display:block;position: absolute;right:20px;top:20px;}

.ContentInfo .list{
	column-count: 1;
}

.ContentInfo .list>div{
	background-image: url('../../images/listPoints.png');background-size:calc((var(--scale)) * 14px);background-position:0 calc(var(--scale) * 9px);background-repeat: no-repeat;padding:0 0 0 calc(var(--scale) * 30px);
	display:block;
	width: calc(100% - calc(var(--scale) * 30px));
	height:auto;
	line-height: 1.5;
	margin-bottom:5px;
	color:rgba(34,30,31,1);
	font-weight: lighter;
	font-size: calc(20px * var(--scale));
}


.ContentInfo .table{color:rgba(34,30,31,1);font-weight: lighter;margin-top:20px;text-align: left;}
.ContentInfo .table td, .ContentInfo .table th{padding:10px;white-space: pre-line;vertical-align: middle;border:1px solid var(--bronze-light);font-size: 100%;}
.ContentInfo .table th{font-size: 80%;}
.ContentInfo .table thead{font-weight: bold;}
.ContentInfo .table tbody td{white-space: pre-line;vertical-align: middle;border:1px solid var(--bronze-light)}

.ContentInfo .table tbody tr{background-color: transparent;transition: background-color .2s;}
.ContentInfo .table tbody tr:hover{background-color: var(--bronze-light);transition: background-color .5s;}



.fullscreen .ContentInfo{padding-left:50px;	width:calc(100% - 70px);}
.fullscreen .ContentInfo h1{margin-top:100px;}
.fullscreen .ContentInfo
{
	--scale:1.8;
}

.fullscreen .ContentInfo .table{margin-top:40px;}
.fullscreen .ContentInfo .table td, .ContentInfo .table th{font-size: calc(100% * (var(--scale) / 1.4));}
.fullscreen  .ContentInfo .table th{font-size: calc(80% * (var(--scale) / 1.4) );}