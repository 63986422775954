.Header{
	width:100%;
	height:calc(var(--header-height) - var(--border-width));
	border-bottom:var(--border-width) solid white;
	background-color: var(--bronze-middle);
	position: absolute;
	top:0;
}
.Header .logo{position: absolute;left:20px;width:150px;height:100%;background-image: url('../../images/lambertz-logo-rgb.svg');background-position: center;background-repeat: no-repeat;}
.Header .right{position: absolute;right:10px;height:100%;width:100%;text-align: right;}
.Header .user{display:inline-block;width:68px;background-size:contain;height:100%;background-image: url('../../images/header_user.png');background-position: center;background-repeat: no-repeat;}
.Header .search{display:inline-block;width:309px;background-size:contain;height:100%;background-image: url('../../images/header_search.png');background-position: center;background-repeat: no-repeat;}
.Header .menu{display:inline-block;width:66px;background-size:contain;height:100%;background-image: url('../../images/header_menu.png');background-position: center;background-repeat: no-repeat;}
