@font-face {
	font-family: 'MuseoSans';
	src:url("MuseoSans-100.otf");	
	font-weight: 100;
}

@font-face {
	font-family: 'MuseoSans';
	src:url("MuseoSans-500.otf");	
	font-weight: 300;
}

@font-face {
	font-family: 'MuseoSans';
	src:url("MuseoSans-500.otf");	
	font-weight: 500;
}

@font-face {
	font-family: 'MuseoSans';
	src:url("MuseoSans-700.otf");	
	font-weight: 700;
}

@font-face {
	font-family: 'MuseoSans';
	src:url("MuseoSans-700.otf");	
	font-weight: 900;
}

@media screen, print {html body{font-family: 'MuseoSans', arial, helvetica, "Droid Sans", sans-serif;}}

