.ContentImage{
	position: relative;
	background-color:var(--bronze-light);
	width:100%;
	height:100%;	
	overflow:hidden
}

.ContentImage img{
	position: absolute;
  top: 50%; 
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%; 
  min-height: 100%; 
  width: auto; 
  height: 100%;
  
	overflow: hidden;
	z-index:1;
	opacity: 0;
	transition: opacity 1s;
}

.wide .ContentImage img
{
	width: 100%; 
  height: auto;
}

.ContentImage img.active
{
	z-index: 95;
	opacity: 1;
	
}


.ContentImage .qrcode{width:100px;height:100px;display:block;position: absolute;right:20px;top:20px;z-index:200;}

.ContentImage .right, .ContentImage .left{position:absolute;background-repeat: no-repeat;background-position:center;background-size:100%;transform:scale(95%);width:50px;height:50px;z-index:100;top:calc(50% - 25px);cursor: pointer;;}
.ContentImage .right:hover, .ContentImage .left:hover{transform:scale(100%);}
.ContentImage .right{background-image: url("../../images/arrowR.png");right:10px;}
.ContentImage .left{background-image: url("../../images/arrowL.png");left:10px;}

.ContentImage .dots{position: absolute;bottom:15px;width:100%;height:20px;z-index:101;pointer-events: none;text-align: center;}
.ContentImage .dots>*{width:10px;height:10px;border:2px solid white;border-radius: 50%;background-color:white;transition: background-color .5s;display:inline-block;margin:0 2px;}
.ContentImage .dots>*.active{background-color:var(--bronze-dark);transition: background-color 1s;}