.Content{

	width:100%;
	position: absolute;
	bottom:var(--footer-height);
	top:var(--header-height);
	--stage_navigation-height:75px;
	color:white;
}

.Content>.left{position: absolute;width:calc(65% - var(--border-width));height: 100%;border-right:var(--border-width) solid white;}
.Content>.right{position: absolute;width:35%;height: 100%;right:0;background-color: var(--bronze-light);color:var(--red);}


.Content>.left .stage{position:absolute;top:0;width:100%;bottom:var(--stage_navigation-height);background-color:var(--bronze-light)}
.Content>.left .stageNavigation{position:absolute;height:calc(var(--stage_navigation-height) - var(--border-width));border-top:var(--border-width) solid white;width:100%;bottom:0;background-color: var(--bronze-dark);}

.Content>.left .stageNavigation .title{position: absolute;right:50px;top:0;line-height: var(--stage_navigation-height);font-size: 20px;}

.Content>.left .stageNavigation>.nav{height:100%;margin-left:10px;}
.Content>.left .stageNavigation>.nav>*{display:inline-block;width:80px;height:100%;background-size:calc(100% - 20px);background-position: center;background-repeat: no-repeat;}
.Content>.left .stageNavigation ._images{background-image: url('../../images/fotoNormal.png');}
.Content>.left .stageNavigation ._images.active{background-image: url('../../images/fotoActive.png');}
.Content>.left .stageNavigation ._videos{background-image: url('../../images/playNormal.png');}
.Content>.left .stageNavigation ._videos.active{background-image: url('../../images/playActive.png');}
.Content>.left .stageNavigation ._3d{background-image: url('../../images/3dNormal.png');}
.Content>.left .stageNavigation ._3d.active{background-image: url('../../images/3dActive.png');}
.Content>.left .stageNavigation ._info{background-image: url('../../images/infoNormal.png');}
.Content>.left .stageNavigation ._info.active{background-image: url('../../images/infoActive.png');}

.Content>.left .stageNavigation .active{background-color: white;}


.Content>.left .stageNavigation .fullscreen{width:40px;height:40px;position: absolute;right:5px;top:15px;background-image: url('../../images/scaleMode.png');background-size: 75%;background-repeat: no-repeat;background-position:center;}

.Content.fullscreen>.left .stageNavigation .fullscreen{background-image: url('../../images/scaleMode.png');}

.Content>.right .head{position: relative;padding:20px;}
.Content>.right .head>h1{font-weight: bold;font-size: 30px;line-height:1.15}
.Content>.right .head>h2{font-weight: bold;font-size: 20px;line-height:1.2}
.Content>.right .head>h3{font-weight: normal;font-size: 20px;color:var(--bronze-dark);line-height:1.2}
.Content>.right .head>img{position: absolute;right:20px;top:0;}
.Content>.right .head>.hr{width:100%;height:1px;background-color:var(--bronze-dark);margin-top:10px;}

.Content>.right .body{position: relative;padding:20px;font-size:20px;line-height: 1.4; width:100%}
.Content>.right .body>p{width:100%}
.Content>.right .body>p>.lab{float: left; width:calc(100% - 90px);margin-right:0px; overflow: hidden; white-space: nowrap }
.Content>.right .body>p>.lab:after{ content: " ..................................................................................................................................................................."}
.Content>.right .body>p>.val{float: left; width: 50px; overflow: hidden;text-align: right;}


.Content>.right .foot{position:absolute;bottom:0;width:100%;}
.Content>.right .foot>img{position:absolute;width:100%;bottom:30px;}
.Content>.right .foot>.bg{position:absolute;bottom:10px;width:100%;height:300px;background-size:contain;background-position: bottom center;background-repeat: no-repeat;background-image:url('../../images/lambertz-sonne-footer.png');}

.Content>.right .foot .qrcode{width:128px;height:128px;display:block;position: absolute;left:calc(50% - 64px);top:-350px;z-index:200}

.Content>.right .ing_img{background-size:cover;background-position:10% 20%;background-repeat: no-repeat;width:100%;height: 100%;;}